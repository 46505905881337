import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';

import style from './BaseLayout.module.scss';

import PrivateRoute from 'routes/PrivateRouter';
import { ROUTES } from 'routes/routes';
import { Roles } from 'helpers/roles';
import RootStore from 'stores/RootStore';

import AccessDenied from 'components/Errors/AccessDenied/AccessDenied';
import AppHeader from 'components/Header/AppHeader';
import Dashboard from 'pages/Dashboard/Dashboard';
import StaffDashboard from 'pages/StaffDashboard/StaffDashboard';
import BookingPage from 'pages/BookingPage/BookingPage';
import MenuPage from 'pages/MenuPage/MenuPage';
import EventPage from 'pages/EventPage/EventPage';
import ManagerPage from 'pages/ManagerPage/ManagerPage';
import CateringPage from 'pages/CateringPage/CateringPage';
import { UserProfile } from 'pages/UserProfile/UserProfile';
import UsersPage from 'pages/UsersPage/UsersPage';
import UserPage from 'pages/UserPage/UserPage';

@observer
class BaseLayout extends Component {
  render() {
    return (
      <div className={classNames(style.layout, style.layout__base)}>
        {RootStore.accessDeniedError ? (
          <AccessDenied />
        ) : (
          <>
            <AppHeader />

            <main className={style.main}>
              <Switch>
                <Route path={ROUTES.profile} component={UserProfile} />
                <PrivateRoute
                  path={ROUTES.users}
                  component={UsersPage}
                  roles={[Roles.ADMIN]}
                />
                <PrivateRoute
                  path={ROUTES.user}
                  component={UserPage}
                  roles={[Roles.ADMIN]}
                />
                <PrivateRoute
                  path={ROUTES.dashboard}
                  component={Dashboard}
                  roles={[Roles.SECRETARY, Roles.MASTER_BOOKING]}
                />
                <PrivateRoute
                  path={ROUTES.menu}
                  component={MenuPage}
                  roles={[
                    Roles.SECRETARY,
                    Roles.CATERER_MANAGER,
                    Roles.CATERER_STAFF,
                    Roles.MASTER_BOOKING,
                    Roles.SERVICE_STAFF,
                  ]}
                />
                <PrivateRoute
                  path={ROUTES.event}
                  component={EventPage}
                  roles={[
                    Roles.SECRETARY,
                    Roles.CATERER_MANAGER,
                    Roles.CATERER_STAFF,
                    Roles.MASTER_BOOKING,
                    Roles.SERVICE_STAFF,
                  ]}
                />
                <PrivateRoute
                  path={ROUTES.bookingNew}
                  component={BookingPage}
                  roles={[Roles.SECRETARY, Roles.MASTER_BOOKING]}
                />
                <PrivateRoute
                  path={ROUTES.bookingEdit}
                  component={BookingPage}
                  roles={[Roles.SECRETARY, Roles.MASTER_BOOKING]}
                />
                <PrivateRoute
                  path={ROUTES.manager}
                  component={ManagerPage}
                  roles={[Roles.CATERER_MANAGER]}
                />
                <PrivateRoute
                  path={ROUTES.cateringEdit}
                  component={CateringPage}
                  roles={[Roles.CATERER_MANAGER, Roles.CATERER_STAFF]}
                />
                <PrivateRoute
                  path={ROUTES.staffDashboard}
                  component={StaffDashboard}
                  roles={[Roles.CATERER_STAFF, Roles.SERVICE_STAFF]}
                />
              </Switch>
            </main>
          </>
        )}
      </div>
    );
  }
}

export default BaseLayout;
